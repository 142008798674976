<template>
  <div class="login">
    <div class="d-flex justify-content-end d-none d-md-flex">           
        <img src="@/assets/images/bgr/login-img.jpg" class="login__img animateScroll fade-down--img" alt="Luminor Apartments" />
    </div>
    
    <div class="login__content">
      <div class="login__header">
        <header class="main-header sticky-header-top" >
            <b-container fluid="xl">
                <b-row>
                    <b-col cols="12">                 
                        <b-navbar toggleable="md">
                            <router-link class="logo" :to="{path: '/'}">Luminor</router-link>
                            <div class="main-header__line"></div>                 
                        </b-navbar>
                    </b-col>
                </b-row>        
            </b-container>
        </header>
      </div>      
      <div class="login__main-content">
        <b-container fluid="xl">
          <b-row>
            <b-col cols="12" md="6" lg="5" xl="4" class="login__inner-content">
              
                <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"><span class="gold-lined-text animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">{{tr('login_welcome')}}</span></h2> 
                <div class="login__lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">                  
                  {{tr('login_lead')}}
                </div> 
                <validation-observer v-slot="{ handleSubmit }" tag="div" class="form-wrap">
                    <form @submit.prevent="handleSubmit(doLogin)">
                      <div>
                        <!-- <ValidationProvider rules="required" v-slot="{ failedRules }"> -->
                          <b-form-group   
                            id="login-lang"
                            :label="tr('login_form_lang')"
                            label-for="lamg"  
                            class="animateScroll login__lang-chooser"    
                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"
                          >                                                        
                            <div class="form-input-wrap ">
                                <LangChooser :homepage='true'/>                               
                            </div>
                            <!-- <div class="error">{{getErrorMessage("email", Object.entries(failedRules)[0])}}</div> -->
                          </b-form-group>
                      <!-- </ValidationProvider> -->
                        <ValidationProvider rules="email|required" v-slot="{ failedRules }">
                          <b-form-group   
                            id="login-email"
                            :label="tr('login_form_input_email')"
                            label-for="email"  
                            class="animateScroll"    
                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')"
                          >                                                        
                            <div class="form-input-wrap">
                                <b-form-input
                                    class="animateScroll"
                                    id="email"
                                    v-model="loginForm.logInEmail"
                                    type="text"
                                    :placeholder="tr('login_form_email_placeholder')"
                                    @focus="focusedParent($event)"
                                    @focusout="focusedOutParent($event)"
                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-5')"
                                    ></b-form-input>
                            </div>
                            <div class="error">{{getErrorMessage("email", Object.entries(failedRules)[0])}}</div>
                          </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider rules="required"  v-slot="{ failedRules }" vid="confirmation">                    
                          <b-form-group   
                            id="login-psw"
                            :label="tr('login_form_input_psw')"
                            label-for="password"  
                            class="animateScroll"    
                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-6')"
                          >                                                        
                            <div class="form-input-wrap">
                                <b-form-input
                                    class="animateScroll"
                                    id="password"
                                    v-model="loginForm.psw"
                                    type="password"
                                    :placeholder="tr('login_form_psw_placeholder')"
                                    @focus="focusedParent($event)"
                                    @focusout="focusedOutParent($event)"
                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-7')"
                                    ></b-form-input>
                            </div>
                            <div class="error">{{getErrorMessage("psw", Object.entries(failedRules)[0])}}</div>
                          </b-form-group>
                        </ValidationProvider>           
                      </div>           
                      <div class="error" style="margin-bottom: 30px">{{ error }}</div>
                      <div class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-8')">
                        <button type="submit" class="btn btn--black btn--arrow "><span>{{tr('btn_belepes')}}</span></button>
                      </div>
                    </form>
                </validation-observer>
              
            </b-col>
          </b-row>
        </b-container>        
      </div>
    </div>
    <Popup />
  </div>  
</template>

<script>
import apiHandlerMI from "@/mixins/apiHandlerMI"; 
import LangChooser from "@/components/layout/LangSelector.vue"
import Popup from "@/components/Popup.vue"
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
// import errorMessages from "@/assets/errorMessages.json";
import {setInteractionMode} from 'vee-validate';
import {required, email, confirmed} from "vee-validate/dist/rules";

setInteractionMode('eager');
extend('required', {
  ...required,
  message: "{_field_} is required."
});
extend("email", {
  ...email,
  message: "Type only valid email",
});
extend("confirmed", {
  ...confirmed,
  message: "Type the same password",
});

export default {
  data() {
    return {
      loginForm: {
        logInEmail: undefined,
        psw: undefined, 
        lang: null
      },
      error: '',      
    }
  },
    components: {
    ValidationProvider,
    ValidationObserver,
    LangChooser,
    Popup
  },
  mixins: [apiHandlerMI],
  //<STEP-2> Letrehozzuk  a doLogin metodust, amely az apiHandlerMI altalanos post fuggvenyet fogja hasznalni. A post szamara
  // megadjuk az url-t, data-t, esetlegesen  a config-ot.
  methods: {    
    doLogin() {
      const _this = this;
      this.post('login', 
        {
            email: _this.loginForm.logInEmail, 
            password: _this.loginForm.psw
        }, 
        {},
        function (response) {
            if (response) {                      
              localStorage.setItem(_this.CONFIG.localStorageName, response.data.token)
              _this.$store.state.userData = response.data.user;
              _this.$store.state.token = response.data.token;                                        
              _this.$router.push({name: 'Home', params:{lang: _this.selectedLanguage}});              
              // console.log('sikeres login', _this.selectedLanguage)             
            }
          }, function (error) {
            console.log('error')
            if (error.data.error === 'Not found!') {
              _this.error = 'Wrong user name or password'
            }
          }
      )
    },
     focusedParent(event){                
            if (event) {                
                let focusedElement = event.target
                focusedElement.classList.add('focused')        
                focusedElement.parentNode.classList.add('focused')                
            }            
        },
        focusedOutParent(event){
            if (event) {                
                let focusedElement = event.target
                focusedElement.classList.remove('focused')        
                focusedElement.parentNode.classList.remove('focused')                
            }
        },
  }
}

</script>

