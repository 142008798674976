<template>
    <div class="modal" id="modal" :class="popupShow ? 'modal-show':''" v-on:click="close">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body p-2">
                     <button type="button" class="close mb-2" data-dismiss="modal" aria-label="Close" v-on:click="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <iframe id="video" width="100%" height="480" src="https://www.youtube.com/embed/Ujk8KRWHuCI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
        popupShow: true
    }
  },
  methods: {    
    close() {
        this.popupShow = false;
        let video = document.getElementById("video")
        video.removeAttribute("src","");
    }
  }
}
</script>